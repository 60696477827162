import { useEffect, useRef } from 'react';
import { Bubble } from './Bubble';
import type { MessageAttributes } from '../../services';
import { useGetConversationMessagesQuery } from '../../services';
import { ChatWrapper } from './styles';

export const Chat = ({
  conversationId,
}: Pick<MessageAttributes, 'conversationId'>) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);

  const { data, loading, error, startPolling } =
    useGetConversationMessagesQuery({
      variables: {
        conversationId,
      },
      // todo: bumping react to 18 breaks the polling within apollo, redo pollInterval when we upgrade
      // pollInterval: 3000,
    });

  // todo: bumping react to 18 breaks the polling within apollo, the useEffect below is a workaround
  useEffect(() => {
    startPolling(3000);
  }, [startPolling]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'auto' });
  });

  return (
    <ChatWrapper>
      {loading && <div>...Loading</div>}
      {error && <div>Could not load History</div>}
      {data &&
        data?.getConversationMessages?.map((message) => (
          <Bubble key={message?.id} {...message} />
        ))}
      <div ref={bottomRef} />
    </ChatWrapper>
  );
};
