import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import type { Message } from '../../../services';
import {
  BubbleWrapper,
  SpeechBubble,
  Title,
  Time,
  MessageWrapper,
  ErrorMessage,
} from './styles';
import { hexToRGB, renderMessageTimeStamp } from '../../../utils';
import { MessageIcon } from '../../../assets/message-icon';
import type { SmsType } from './types';
import { EMPTY_VALUE } from '../../../App.constants';

export const Bubble = (message: Message) => {
  // todo:  hard coding SmSType for now until we have a proper type
  const smsType: SmsType = 'OUTGOING';
  const {
    attributes: {
      content,
      smsStatus,
      audit: { created },
    },
  } = message;

  const bubbleColor =
    smsType === 'OUTGOING'
      ? hexToRGB(colors.secondaryBlue.blue01, 0.6)
      : colors.grey.grey02;

  const SmsStatusSuccess =
    smsStatus === 'SENT' || smsStatus === 'DELIVERED' || smsStatus === 'QUEUED';

  const renderSmsStatusIcon = () => {
    if (smsStatus === 'SENT' || smsStatus === 'DELIVERED')
      return <BTHealthIcon icon="Check" size={12} color={colors.grey.grey08} />;
    if (smsStatus === 'QUEUED')
      return <BTHealthIcon icon="Time" size={14} color={colors.grey.grey08} />;
    // everything else is a failure
    return <BTHealthIcon icon="Warning" size={14} color={colors.grey.grey08} />;
  };

  return (
    /* todo: eventually smsType needs to be determined by the message type, the attribute is yet to be added */
    <>
      <BubbleWrapper smsType="OUTGOING">
        <MessageIcon />
        <SpeechBubble color={bubbleColor}>
          <Title>
            <Time>
              {created ? renderMessageTimeStamp(created) : EMPTY_VALUE}
            </Time>
            {renderSmsStatusIcon()}
          </Title>
          <MessageWrapper>{content}</MessageWrapper>
        </SpeechBubble>
      </BubbleWrapper>
      {!SmsStatusSuccess && (
        <ErrorMessage>
          Not delivered{' '}
          <BTHealthIcon icon="Time" size={14} color={colors.rag.light.red} />
        </ErrorMessage>
      )}
    </>
  );
};
