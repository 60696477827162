import { BTHealthIcon, colors, SubHeader } from '@bt-healthcare/ui-toolkit';
import { DirectMessagesWrapper, IconWrapper } from './styles';
import { useStore } from '../../../store';

export const DirectMessages = () => {
  const [
    setHideChat,
    setConversationId,
    setRecipient,
    setRecipientName,
    setRecipientId,
  ] = useStore((state) => [
    state.setHideChat,
    state.setConversationId,
    state.setRecipient,
    state.setRecipientName,
    state.setRecipientId,
  ]);
  const setNewMessage = () => {
    setHideChat(false);
    setConversationId('');
    setRecipient('');
    setRecipientName('');
    setRecipientId('');
  };
  return (
    <DirectMessagesWrapper>
      <SubHeader color="indigo08">Direct Messages</SubHeader>
      <IconWrapper onClick={setNewMessage}>
        <BTHealthIcon icon="Add" size={20} color={colors.base.white} />
      </IconWrapper>
    </DirectMessagesWrapper>
  );
};
