import * as yup from 'yup';
import { REQUIRED_FIELD_TEXT } from '../App.constants';

// Validation helpers
const yupUUID = () => yup.string().uuid().required(REQUIRED_FIELD_TEXT);

const yupRequiredStringWithMinMax = (minChars: number, maxChars: number) =>
  yup
    .string()
    .min(minChars, REQUIRED_FIELD_TEXT)
    .max(maxChars)
    .required(REQUIRED_FIELD_TEXT);

const yupSelectedRecipient = () =>
  yup
    .object()
    .shape({
      label: yup.string().required(REQUIRED_FIELD_TEXT),
      value: yup.string().required(REQUIRED_FIELD_TEXT),
      recipientId: yup.string().required(REQUIRED_FIELD_TEXT),
      recipientName: yup.string().required(REQUIRED_FIELD_TEXT),
    })
    .nullable()
    .required(REQUIRED_FIELD_TEXT);

// Exported validations
export const uuidValidation = () =>
  yup.object().shape({
    careSettingPatientId: yupUUID(),
  });

export const clinicianValidation = (minChars: number, maxChars: number) =>
  yup.object().shape({
    selectedRecipient: yupSelectedRecipient(),
    message: yupRequiredStringWithMinMax(minChars, maxChars),
  });

export const messageValidation = (minChars: number, maxChars: number) =>
  yup.object().shape({
    message: yupRequiredStringWithMinMax(minChars, maxChars),
  });
