import { fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const MessagePlaceholderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: ${fontSizes.base};
  margin-top: ${spacing.s8};
  margin-bottom: ${spacing.s1};
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: ${fontSizes.sm};
`;
