export const SmartMessagingIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3334 0.333252L1.66671 0.333252C0.933374 0.333252 0.333374 0.933252 0.333374 1.66659L0.333374 13.6666L3.00004 10.9999L12.3334 10.9999C13.0667 10.9999 13.6667 10.3999 13.6667 9.66659L13.6667 1.66659C13.6667 0.933252 13.0667 0.333252 12.3334 0.333252ZM12.3334 9.66659L2.44671 9.66659L1.66671 10.4466L1.66671 1.66659L12.3334 1.66659L12.3334 9.66659ZM3.66671 4.99992H5.00004V6.33325H3.66671V4.99992ZM9.00004 4.99992H10.3334L10.3334 6.33325H9.00004L9.00004 4.99992ZM6.33337 4.99992H7.66671V6.33325H6.33337V4.99992Z"
      fill="#5514B4"
    />
  </svg>
);
