import type { NamedSet } from 'zustand/middleware';
import type { StoreApi } from 'zustand';
import { isEmpty } from 'ramda';
import type { GraphQLErrors, NetworkError } from '@apollo/client/errors';

import type { AppState } from './types';

export type ErrorType = 'Network' | 'GraphQLError' | 'Unhandled';

export type Errors = {
  error: GraphQLErrors | NetworkError | string;
  errorType: ErrorType;
};

export const errorSlice = (
  set: NamedSet<AppState>,
  get: StoreApi<AppState>['getState']
) => ({
  errors: {} as Errors,
  errorMessage: (text: string) => {
    const { errors } = get();
    if (isEmpty(errors)) return '';
    // TODO: Determine the error type and display error codes where applicable
    return `An error has occurred when ${text}.
      If the issue persists, contact our team for help on 00663 12378.`;
  },
  setErrors: (errors: Errors) => set({ errors }, false, 'error/setErrors'),
  resetErrors: () => set({ errors: {} as Errors }, false, 'errors/resetErrors'),
});
