import type { NamedSet } from 'zustand/middleware';

import type { AppConfig } from '../types';
import type { AppState } from './types';

export const uiSlice = (set: NamedSet<AppState>) => ({
  menuHeader: 'Smart Messaging',
  appConfig: {
    careSettingId: '',
    organisationName: '',
  } as AppConfig,
  setMenuHeader: (menuHeader: string) =>
    set({ menuHeader }, false, 'ui/setMenuHeader'),
  setAppConfig: (appConfig: AppConfig) =>
    set({ appConfig }, false, 'ui/setAppConfig'),
});
