import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { uiSlice } from './uiSlice';
import { messageSlice } from './messageSlice';
import { errorSlice } from './errorSlice';
import type { AppState } from './types';

const rootSlice: StateCreator<
  AppState,
  [
    ['zustand/persist', unknown],
    ['zustand/devtools', never],
    ['zustand/immer', never]
  ],
  []
> = (set, get) => ({
  ...uiSlice(set),
  ...messageSlice(set),
  ...errorSlice(set, get),
});

export const useStore = create<AppState>()(
  persist(devtools(immer(rootSlice)), { name: 'persist_store' })
);

export const clearStore = () => {
  useStore.persist.clearStorage();
};
