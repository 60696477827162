import styled from 'styled-components';
import type { NotificationAlignment } from './types';

type ResponseNotificationProps = {
  alignment: NotificationAlignment;
};

export const ResponseNotificationWrapper = styled.div<ResponseNotificationProps>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  margin: 0;
`;
