import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: string;
};

export type AimHumanName = {
  __typename?: 'AIMHumanName';
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Scalars['String']>;
};

export type Audit = {
  __typename?: 'Audit';
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByUser?: Maybe<UserProfile>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedByUser?: Maybe<UserProfile>;
  version: Scalars['Int'];
};

export type CareSetting = Identifier & {
  __typename?: 'CareSetting';
  attributes: CareSettingAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingAttributes = {
  __typename?: 'CareSettingAttributes';
  contactPoints?: Maybe<Array<Maybe<ContactPoint>>>;
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  res_phone?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

export type CareSettingClinician = Identifier & {
  __typename?: 'CareSettingClinician';
  attributes: CareSettingClinicianAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianAttributes = {
  __typename?: 'CareSettingClinicianAttributes';
  audit?: Maybe<Audit>;
  careSettingClinicianModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  careSettingClinicianModuleRoles: Array<Maybe<UserModuleRole>>;
  careSettingClinicianUser?: Maybe<User>;
  careSettingId: Scalars['ID'];
  /** @deprecated Use userId */
  clinicianId?: Maybe<Scalars['ID']>;
  /** @deprecated Review usage with contactPoints */
  contactInformation?: Maybe<CareSettingClinicianAttributesContactInformation>;
  contactPoints?: Maybe<Array<Maybe<ContactPoint>>>;
  displayName?: Maybe<Scalars['String']>;
  /** @deprecated Use displayName */
  dnHumanNameTextCurrent?: Maybe<Scalars['String']>;
  /** @deprecated Use res_mobileNumber */
  dnPhoneNumberCurrent?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  humanNames?: Maybe<Array<Maybe<HumanName>>>;
  receiveBedAvailabilitySmsAlerts?: Maybe<Scalars['Boolean']>;
  res_mobileNumber?: Maybe<Scalars['String']>;
  specialties?: Maybe<Array<Maybe<SpecialtyType>>>;
  surname?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CareSettingClinicianAttributesContactInformation = {
  __typename?: 'CareSettingClinicianAttributesContactInformation';
  primaryContactNumber?: Maybe<Scalars['String']>;
  primaryEmailAddress?: Maybe<Scalars['String']>;
};

export type CareSettingPatient = Identifier & {
  __typename?: 'CareSettingPatient';
  attributes: CareSettingPatientAttributes;
  id: Scalars['ID'];
  person: Person;
  type: Scalars['String'];
};

export type CareSettingPatientAttributes = {
  __typename?: 'CareSettingPatientAttributes';
  admissionComments?: Maybe<Scalars['String']>;
  /** Unique ref. to identify a patient e.g. for ESNEFT this is the hospital no. in the format of D1234567 */
  admissionReference?: Maybe<Scalars['String']>;
  audit?: Maybe<Audit>;
  careSettingId?: Maybe<Scalars['ID']>;
  decisionToAdmitDateTime?: Maybe<Scalars['DateTime']>;
  decisionToAdmitWaitTime?: Maybe<Scalars['Int']>;
  furtherWardPreferences?: Maybe<Scalars['String']>;
  isolationType: IsolationType;
  isolationTypeOther?: Maybe<Scalars['String']>;
  origin?: Maybe<OriginType>;
  patientWardPreference?: Maybe<PatientWardPreferencesType>;
  personId: Scalars['ID'];
  requiresIsolationYN?: Maybe<YesNoType>;
  socialCareYN?: Maybe<YesNoType>;
  specialty?: Maybe<SpecialtyType>;
};

export type ContactPoint = {
  __typename?: 'ContactPoint';
  period: Period;
  rank?: Maybe<Scalars['Int']>;
  system: ContactPointsSystem;
  use?: Maybe<ContactPointsUse>;
  value: Scalars['String'];
};

export type ContactPointsSystem =
  | 'EMAIL'
  | 'FAX'
  | 'OTHER'
  | 'PAGER'
  | 'PHONE'
  | 'SMS'
  | 'URL';

export type ContactPointsUse = 'HOME' | 'MOBILE' | 'OLD' | 'TEMP' | 'WORK';

export type Ethnicity =
  | 'ASIAN_OR_ASIAN_BRITISH_ANY_OTHER_ASIAN_BACKGROUND'
  | 'ASIAN_OR_ASIAN_BRITISH_BANGLADESHI'
  | 'ASIAN_OR_ASIAN_BRITISH_INDIAN'
  | 'ASIAN_OR_ASIAN_BRITISH_PAKISTANI'
  | 'BLACK_OR_BLACK_BRITISH_AFRICAN'
  | 'BLACK_OR_BLACK_BRITISH_ANY_OTHER_BLACK_BACKGROUND'
  | 'BLACK_OR_BLACK_BRITISH_CARIBBEAN'
  | 'MIXED_ANY_OTHER_MIXED'
  | 'MIXED_WHITE_AND_ASIAN'
  | 'MIXED_WHITE_AND_BLACK_AFRICAN'
  | 'MIXED_WHITE_AND_BLACK_CARIBBEAN'
  | 'NOT_KNOWN'
  | 'NOT_STATED'
  | 'OTHER_ETHNIC_GROUPS_ANY_OTHER_ETHNIC_GROUP'
  | 'OTHER_ETHNIC_GROUPS_CHINESE'
  | 'WHITE_ANY_OTHER_WHITE_BACKGROUND'
  | 'WHITE_BRITISH'
  | 'WHITE_IRISH';

export type GenderAssignedAtBirth = 'FEMALE' | 'MALE';

export type GenderIdentifiedAs =
  | 'FEMALE'
  | 'MALE'
  | 'NON_BINARY'
  | 'PREFER_NONE';

export type HumanName = {
  __typename?: 'HumanName';
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Array<Maybe<Scalars['String']>>>;
  period: Period;
  prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
  use: HumanNamesUse;
};

export type HumanNamesUse =
  | 'ANONYMOUS'
  | 'MAIDEN'
  | 'NICKNAME'
  | 'OFFICIAL'
  | 'OLD'
  | 'TEMP'
  | 'USUAL';

export type Identifier = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type IsolationType =
  | 'COMMON_COLD'
  | 'COVID_19'
  | 'DV'
  | 'MRSA'
  | 'NONE'
  | 'OTHER'
  | 'PATIENT_AGGRESSIVE'
  | 'PATIENT_CONFUSED';

export type Message = Identifier & {
  __typename?: 'Message';
  attributes: MessageAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type MessageAttributes = {
  __typename?: 'MessageAttributes';
  audit: Audit;
  careSettingId: Scalars['String'];
  content: Scalars['String'];
  conversationId: Scalars['String'];
  recipient?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientType?: Maybe<SmsRecipientType>;
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsStatus: SmsStatus;
};

export type MessageInput = {
  careSettingId: Scalars['String'];
  content: Scalars['String'];
  conversationId?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
  recipientType?: InputMaybe<SmsRecipientType>;
  recipients: Array<Scalars['String']>;
  shortMessageType: SmsShortMessageType;
};

export type Mobility =
  | 'MAXIMUM_ASSISTANCE'
  | 'MINIMUM_ASSISTANCE'
  | 'MODERATE_ASSISTANCE'
  | 'NO_ASSISTANCE';

export type ModuleIdentifier =
  | 'BED_OPTIMISER'
  | 'COMMAND_CENTRE'
  | 'HEALTHYYOU_CLINICIAN'
  | 'HEALTHYYOU_PATIENT'
  | 'SMART_MESSAGING';

export type ModuleRole =
  | 'BED_OPTIMISER_DEFAULT_USER'
  | 'CARE_SETTING_ADMINISTRATOR'
  | 'COMMAND_CENTRE_DEFAULT_USER'
  | 'HEALTHYYOU_CLINICIAN_DEFAULT_USER'
  | 'HEALTHYYOU_PATIENT_DEFAULT_USER'
  | 'SMART_MESSAGING_DEFAULT_USER';

export type Mutation = {
  __typename?: 'Mutation';
  postMessage: Message;
};

export type MutationPostMessageArgs = {
  input: MessageInput;
};

export type OriginType =
  | 'A_E'
  | 'MEDICAL_ASS_UNIT'
  | 'NONE'
  | 'OUTPATIENTS'
  | 'SURGICAL_ASS_UNIT';

export type PatientWardPreferencesType =
  | 'FEMALE_WARD'
  | 'GENDER_NEUTRAL'
  | 'MALE_WARD'
  | 'NONE';

export type Period = {
  __typename?: 'Period';
  end?: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type Person = Identifier & {
  __typename?: 'Person';
  attributes: PersonAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type PersonAttributes = {
  __typename?: 'PersonAttributes';
  audit?: Maybe<Audit>;
  contactInformation?: Maybe<PersonContactInformation>;
  contactParty?: Maybe<Array<Maybe<PersonContactPartyFields>>>;
  personalInformation: PersonPersonalInformation;
  registeredGPSurgery?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type PersonContactInformation = {
  __typename?: 'PersonContactInformation';
  primaryContactNumber?: Maybe<Scalars['String']>;
  primaryEmailAddress?: Maybe<Scalars['String']>;
};

export type PersonContactPartyFields = {
  __typename?: 'PersonContactPartyFields';
  humanNames?: Maybe<Array<Maybe<AimHumanName>>>;
  personRelationshipType?: Maybe<PersonRelationshipType>;
  primaryContactNumber?: Maybe<Scalars['String']>;
};

export type PersonPersonalInformation = {
  __typename?: 'PersonPersonalInformation';
  dateOfBirth?: Maybe<Scalars['Date']>;
  ethnicity?: Maybe<Ethnicity>;
  firstName: Scalars['String'];
  genderAssignedAtBirth?: Maybe<GenderAssignedAtBirth>;
  genderIdentifiedAs: GenderIdentifiedAs;
  mobility?: Maybe<Mobility>;
  nhsNumber?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<PrimaryAddress>;
  pronouns?: Maybe<Pronouns>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type PersonRelationshipType = 'NEXT_OF_KIN' | 'NONE' | 'OTHER';

export type PrimaryAddress = {
  __typename?: 'PrimaryAddress';
  line1?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type Pronouns = 'HE_HIM' | 'SHE_HER' | 'THEY_THEM';

export type Query = {
  __typename?: 'Query';
  careSettingClinicians?: Maybe<Array<CareSettingClinician>>;
  careSettingPatient?: Maybe<CareSettingPatient>;
  getCareSettingConversationHistory?: Maybe<Array<Message>>;
  getConversationMessages?: Maybe<Array<Message>>;
  userProfile?: Maybe<UserProfile>;
};

export type QueryCareSettingCliniciansArgs = {
  careSettingId: Scalars['ID'];
  name: Scalars['String'];
};

export type QueryCareSettingPatientArgs = {
  patientId: Scalars['ID'];
};

export type QueryGetCareSettingConversationHistoryArgs = {
  careSettingId: Scalars['ID'];
  recipientType: SmsRecipientType;
};

export type QueryGetConversationMessagesArgs = {
  conversationId: Scalars['ID'];
};

export type QueryUserProfileArgs = {
  safId: Scalars['String'];
};

export type RegistrationStatus = 'UNVERIFIED' | 'VERIFIED';

export type SmsRecipientType = 'CLINICIAN' | 'PATIENT';

/** The type of SMS message */
export type SmsShortMessageType =
  | 'BROADCAST_EXCLUDED_FROM_CONVERSATION'
  | 'BROADCAST_INCLUDED_IN_CONVERSATION'
  | 'CONVERSATION';

export type SmsStatus = 'DELIVERED' | 'FAILED' | 'NONE' | 'QUEUED' | 'SENT';

export type SpecialtyType =
  | 'CARDIOLOGY'
  | 'EAR_NOSE_AND_THROAT'
  | 'ENDOC_DIABETES'
  | 'GASTRO'
  | 'GENI_MEDICINE'
  | 'GEN_INT_MEDICINE'
  | 'GEN_SURGERY'
  | 'GERI_MEDICINE'
  | 'MAX_FAX'
  | 'NEUROLOGY'
  | 'NONE'
  | 'ORTHO'
  | 'PLASTIC_SURGERY'
  | 'REN_MEDICINE'
  | 'RESP_MEDICINE'
  | 'RHREU'
  | 'STROKE_MEDICINE'
  | 'UROLOGY'
  | 'VASC_SURGERY';

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  dateTime: Scalars['DateTime'];
  decision?: Maybe<TermsAndConditionsStatus>;
  version: Scalars['String'];
};

export type TermsAndConditionsStatus = 'ACCEPTED' | 'DECLINED';

export type User = Identifier & {
  __typename?: 'User';
  attributes: UserAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  audit?: Maybe<Audit>;
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  registrationStatus?: Maybe<RegistrationStatus>;
  safId: Scalars['String'];
  termsAndConditions?: Maybe<TermsAndConditions>;
  /** @deprecated To be removed in a future release */
  userRegistrationId: Scalars['ID'];
  userState?: Maybe<UserState>;
};

export type UserModuleAvailability = Identifier & {
  __typename?: 'UserModuleAvailability';
  attributes: UserModuleAvailabilityAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleAvailabilityAttributes = {
  __typename?: 'UserModuleAvailabilityAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingModuleId: Scalars['ID'];
  moduleId: ModuleIdentifier;
  moduleName: Scalars['String'];
  moduleShortName: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserModuleAvailabilityStatus = 'ACCESSIBLE' | 'VISIBLE';

export type UserModuleRole = Identifier & {
  __typename?: 'UserModuleRole';
  attributes: UserModuleRoleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleRoleAttributes = {
  __typename?: 'UserModuleRoleAttributes';
  careSettingModuleId: Scalars['ID'];
  moduleRoleId: ModuleRole;
  userId: Scalars['ID'];
};

export type UserProfile = Identifier & {
  __typename?: 'UserProfile';
  attributes: UserAttributes;
  careSetting?: Maybe<CareSetting>;
  careSettingClinician?: Maybe<CareSettingClinician>;
  id: Scalars['ID'];
  type: Scalars['String'];
  userModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  userModuleRoles: Array<Maybe<UserModuleRole>>;
};

export type UserState = 'DISABLED' | 'ENABLED';

export type YesNoType = 'NO' | 'NONE' | 'YES';

export type PostMessageMutationVariables = Exact<{
  input: MessageInput;
}>;

export type PostMessageMutation = {
  __typename?: 'Mutation';
  postMessage: {
    __typename?: 'Message';
    attributes: {
      __typename?: 'MessageAttributes';
      careSettingId: string;
      conversationId: string;
      recipients?: Array<string | null> | null;
      recipientName?: string | null;
      recipientId?: string | null;
      recipientType?: SmsRecipientType | null;
      content: string;
      audit: {
        __typename?: 'Audit';
        created?: string | null;
        lastUpdated?: string | null;
        version: number;
      };
    };
  };
};

export type CareSettingCliniciansQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type CareSettingCliniciansQuery = {
  __typename?: 'Query';
  careSettingClinicians?: Array<{
    __typename?: 'CareSettingClinician';
    id: string;
    type: string;
    attributes: {
      __typename?: 'CareSettingClinicianAttributes';
      res_mobileNumber?: string | null;
      careSettingId: string;
      displayName?: string | null;
    };
  }> | null;
};

export type GetCareSettingConversationHistoryQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
  recipientType: SmsRecipientType;
}>;

export type GetCareSettingConversationHistoryQuery = {
  __typename?: 'Query';
  getCareSettingConversationHistory?: Array<{
    __typename?: 'Message';
    id: string;
    type: string;
    attributes: {
      __typename?: 'MessageAttributes';
      careSettingId: string;
      content: string;
      conversationId: string;
      recipientName?: string | null;
      recipientId?: string | null;
      recipientType?: SmsRecipientType | null;
      recipient?: string | null;
      smsStatus: SmsStatus;
      audit: {
        __typename?: 'Audit';
        created?: string | null;
        createdBy?: string | null;
        lastUpdated?: string | null;
        version: number;
      };
    };
  }> | null;
};

export type GetCareSettingPatientQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;

export type GetCareSettingPatientQuery = {
  __typename?: 'Query';
  careSettingPatient?: {
    __typename?: 'CareSettingPatient';
    attributes: {
      __typename?: 'CareSettingPatientAttributes';
      careSettingId?: string | null;
    };
    person: {
      __typename?: 'Person';
      attributes: {
        __typename?: 'PersonAttributes';
        personalInformation: {
          __typename?: 'PersonPersonalInformation';
          firstName: string;
          surname: string;
          title: string;
          dateOfBirth?: string | null;
          nhsNumber?: string | null;
        };
        contactInformation?: {
          __typename?: 'PersonContactInformation';
          primaryContactNumber?: string | null;
        } | null;
      };
    };
  } | null;
};

export type GetConversationMessagesQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;

export type GetConversationMessagesQuery = {
  __typename?: 'Query';
  getConversationMessages?: Array<{
    __typename?: 'Message';
    id: string;
    type: string;
    attributes: {
      __typename?: 'MessageAttributes';
      careSettingId: string;
      content: string;
      conversationId: string;
      recipients?: Array<string | null> | null;
      smsStatus: SmsStatus;
      audit: {
        __typename?: 'Audit';
        created?: string | null;
        lastUpdated?: string | null;
        version: number;
      };
    };
  }> | null;
};

export type GetUserProfileQueryVariables = Exact<{
  safId: Scalars['String'];
}>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  userProfile?: {
    __typename?: 'UserProfile';
    id: string;
    userModuleAvailability: Array<{
      __typename?: 'UserModuleAvailability';
      attributes: {
        __typename?: 'UserModuleAvailabilityAttributes';
        availabilityStatus: UserModuleAvailabilityStatus;
        moduleId: ModuleIdentifier;
        moduleName: string;
        moduleShortName: string;
        userId: string;
      };
    } | null>;
    careSetting?: {
      __typename?: 'CareSetting';
      attributes: {
        __typename?: 'CareSettingAttributes';
        name: string;
        shortName: string;
      };
    } | null;
    attributes: {
      __typename?: 'UserAttributes';
      userState?: UserState | null;
      defaultCareSettingId: string;
    };
  } | null;
};

export const PostMessageDocument = gql`
  mutation PostMessage($input: MessageInput!) {
    postMessage(input: $input) {
      attributes {
        audit {
          created
          lastUpdated
          version
        }
        careSettingId
        conversationId
        recipients
        recipientName
        recipientId
        recipientType
        content
      }
    }
  }
`;
export type PostMessageMutationFn = Apollo.MutationFunction<
  PostMessageMutation,
  PostMessageMutationVariables
>;

/**
 * __usePostMessageMutation__
 *
 * To run a mutation, you first call `usePostMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMessageMutation, { data, loading, error }] = usePostMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostMessageMutation,
    PostMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PostMessageMutation, PostMessageMutationVariables>(
    PostMessageDocument,
    options
  );
}
export type PostMessageMutationHookResult = ReturnType<
  typeof usePostMessageMutation
>;
export type PostMessageMutationResult =
  Apollo.MutationResult<PostMessageMutation>;
export type PostMessageMutationOptions = Apollo.BaseMutationOptions<
  PostMessageMutation,
  PostMessageMutationVariables
>;
export const CareSettingCliniciansDocument = gql`
  query CareSettingClinicians($careSettingId: ID!, $name: String!) {
    careSettingClinicians(careSettingId: $careSettingId, name: $name) {
      attributes {
        res_mobileNumber
        careSettingId
        displayName
      }
      id
      type
    }
  }
`;

/**
 * __useCareSettingCliniciansQuery__
 *
 * To run a query within a React component, call `useCareSettingCliniciansQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareSettingCliniciansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareSettingCliniciansQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCareSettingCliniciansQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareSettingCliniciansQuery,
    CareSettingCliniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CareSettingCliniciansQuery,
    CareSettingCliniciansQueryVariables
  >(CareSettingCliniciansDocument, options);
}
export function useCareSettingCliniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareSettingCliniciansQuery,
    CareSettingCliniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareSettingCliniciansQuery,
    CareSettingCliniciansQueryVariables
  >(CareSettingCliniciansDocument, options);
}
export type CareSettingCliniciansQueryHookResult = ReturnType<
  typeof useCareSettingCliniciansQuery
>;
export type CareSettingCliniciansLazyQueryHookResult = ReturnType<
  typeof useCareSettingCliniciansLazyQuery
>;
export type CareSettingCliniciansQueryResult = Apollo.QueryResult<
  CareSettingCliniciansQuery,
  CareSettingCliniciansQueryVariables
>;
export const GetCareSettingConversationHistoryDocument = gql`
  query GetCareSettingConversationHistory(
    $careSettingId: ID!
    $recipientType: SmsRecipientType!
  ) {
    getCareSettingConversationHistory(
      careSettingId: $careSettingId
      recipientType: $recipientType
    ) {
      id
      type
      attributes {
        audit {
          created
          createdBy
          lastUpdated
          version
        }
        careSettingId
        content
        conversationId
        recipientName
        recipientId
        recipientType
        recipient
        smsStatus
      }
    }
  }
`;

/**
 * __useGetCareSettingConversationHistoryQuery__
 *
 * To run a query within a React component, call `useGetCareSettingConversationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareSettingConversationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareSettingConversationHistoryQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *      recipientType: // value for 'recipientType'
 *   },
 * });
 */
export function useGetCareSettingConversationHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCareSettingConversationHistoryQuery,
    GetCareSettingConversationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareSettingConversationHistoryQuery,
    GetCareSettingConversationHistoryQueryVariables
  >(GetCareSettingConversationHistoryDocument, options);
}
export function useGetCareSettingConversationHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareSettingConversationHistoryQuery,
    GetCareSettingConversationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareSettingConversationHistoryQuery,
    GetCareSettingConversationHistoryQueryVariables
  >(GetCareSettingConversationHistoryDocument, options);
}
export type GetCareSettingConversationHistoryQueryHookResult = ReturnType<
  typeof useGetCareSettingConversationHistoryQuery
>;
export type GetCareSettingConversationHistoryLazyQueryHookResult = ReturnType<
  typeof useGetCareSettingConversationHistoryLazyQuery
>;
export type GetCareSettingConversationHistoryQueryResult = Apollo.QueryResult<
  GetCareSettingConversationHistoryQuery,
  GetCareSettingConversationHistoryQueryVariables
>;
export const GetCareSettingPatientDocument = gql`
  query getCareSettingPatient($patientId: ID!) {
    careSettingPatient(patientId: $patientId) {
      attributes {
        careSettingId
      }
      person {
        attributes {
          personalInformation {
            firstName
            surname
            title
            dateOfBirth
            nhsNumber
          }
          contactInformation {
            primaryContactNumber
          }
        }
      }
    }
  }
`;

/**
 * __useGetCareSettingPatientQuery__
 *
 * To run a query within a React component, call `useGetCareSettingPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareSettingPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareSettingPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetCareSettingPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCareSettingPatientQuery,
    GetCareSettingPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareSettingPatientQuery,
    GetCareSettingPatientQueryVariables
  >(GetCareSettingPatientDocument, options);
}
export function useGetCareSettingPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareSettingPatientQuery,
    GetCareSettingPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareSettingPatientQuery,
    GetCareSettingPatientQueryVariables
  >(GetCareSettingPatientDocument, options);
}
export type GetCareSettingPatientQueryHookResult = ReturnType<
  typeof useGetCareSettingPatientQuery
>;
export type GetCareSettingPatientLazyQueryHookResult = ReturnType<
  typeof useGetCareSettingPatientLazyQuery
>;
export type GetCareSettingPatientQueryResult = Apollo.QueryResult<
  GetCareSettingPatientQuery,
  GetCareSettingPatientQueryVariables
>;
export const GetConversationMessagesDocument = gql`
  query GetConversationMessages($conversationId: ID!) {
    getConversationMessages(conversationId: $conversationId) {
      id
      type
      attributes {
        audit {
          created
          lastUpdated
          version
        }
        careSettingId
        content
        conversationId
        recipients
        smsStatus
      }
    }
  }
`;

/**
 * __useGetConversationMessagesQuery__
 *
 * To run a query within a React component, call `useGetConversationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationMessagesQuery,
    GetConversationMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationMessagesQuery,
    GetConversationMessagesQueryVariables
  >(GetConversationMessagesDocument, options);
}
export function useGetConversationMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationMessagesQuery,
    GetConversationMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationMessagesQuery,
    GetConversationMessagesQueryVariables
  >(GetConversationMessagesDocument, options);
}
export type GetConversationMessagesQueryHookResult = ReturnType<
  typeof useGetConversationMessagesQuery
>;
export type GetConversationMessagesLazyQueryHookResult = ReturnType<
  typeof useGetConversationMessagesLazyQuery
>;
export type GetConversationMessagesQueryResult = Apollo.QueryResult<
  GetConversationMessagesQuery,
  GetConversationMessagesQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile($safId: String!) {
    userProfile(safId: $safId) {
      id
      userModuleAvailability {
        attributes {
          availabilityStatus
          moduleId
          moduleName
          moduleShortName
          userId
        }
      }
      careSetting {
        attributes {
          name
          shortName
        }
      }
      attributes {
        userState
        defaultCareSettingId
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      safId: // value for 'safId'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export type GetUserProfileQueryHookResult = ReturnType<
  typeof useGetUserProfileQuery
>;
export type GetUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileLazyQuery
>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
