import { colors, fontSizes, Text } from '@bt-healthcare/ui-toolkit';
import { ResponseNotificationWrapper } from './styles';
import type { Props } from './types';

export const ResponseNotification = (props: Props) => {
  const { message, notificationType, alignment = 'align-right' } = props;

  return (
    <ResponseNotificationWrapper alignment={alignment}>
      <Text
        color={
          notificationType === 'error'
            ? colors.notifications.light.errorDark
            : colors.secondaryBlue.blue06
        }
        fontSize={fontSizes.sm}
        data-testid={`${notificationType}-message`}
      >
        {message}
      </Text>
    </ResponseNotificationWrapper>
  );
};
