import { spacing, colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { MHIProps } from './types';

export const MessageHistoryItemWrapper = styled.div<MHIProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${({ isActive }) =>
    isActive ? colors.base.light : colors.base.white};
  padding: ${spacing.s2} ${spacing.s4};
  border-radius: 8px;
  margin-bottom: ${spacing.s2};
  cursor: pointer;

  > :first-child {
    margin-right: ${spacing.s4};
    display: flex;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PhoneNumber = styled.div`
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey10};
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: ${spacing.s1};
`;

export const TimeStamp = styled.div`
  font-size: ${fontSizes.xs};
  color: ${colors.primaryIndigo.indigo08};
  font-weight: 400;
`;

export const Message = styled.div`
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey10};
  font-weight: 400;
  margin-top: ${spacing.s1};

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
`;
