import styled from 'styled-components';
import { colors } from '@bt-healthcare/ui-toolkit';

export const MessageIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 16px;
  background-color: ${colors.primaryIndigo.indigo02};
`;
