import { RetryLink } from '@apollo/client/link/retry';

/** Options below are default for clarity - see https://www.apollographql.com/docs/react/api/link/apollo-link-retry/ */
export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});
