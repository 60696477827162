import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';

import App from './App';
import {
  AuthApolloProvider,
  AuthenticationTemplate,
  AuthProvider,
} from './auth';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <AuthProvider>
      <AuthenticationTemplate>
        <AuthApolloProvider>
          <Router>
            <App />
          </Router>
        </AuthApolloProvider>
      </AuthenticationTemplate>
    </AuthProvider>
  </StrictMode>
);
