import React, { useEffect, useRef } from 'react';
import type { FieldError } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextArea,
  Button,
  BTHealthIcon,
  SubHeader,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';
import { useStore } from '../../store';
import { usePostMessageMutation } from '../../services';
import { Chat } from '../Chat';
import { MessagePlaceholder } from '../MessagePlaceholder';
import { ResponseNotification } from '../ResponseNotification';
import { RHFAsyncDropDown } from '../RHFAsyncDropDown';
import { SelectCustomComponents } from './SelectCustomComponents';
import { useLoadClinicians } from './hooks/useLoadClinicians';
import { characterCount } from '../../utils';
import { clinicianValidation, messageValidation } from '../../validation';
import { SMS_MIN_CHARS, SMS_MAX_CHARS } from '../../App.constants';
import type { MessageFormData } from './types';
import {
  NewMessageWrapper,
  DropdownWrapper,
  MessageFieldWrapper,
  ChatWrapper,
  ButtonWrapper,
  TitleWrapper,
  TitleIcon,
} from './styles';

export const NewMessage = () => {
  const defaultMessage = '';

  const [
    { careSettingId },
    hideChat,
    setHideChat,
    conversationId,
    recipient,
    setConversationId,
    setRecipient,
    setRecipientId,
    setRecipientName,
    recipientId,
    recipientName,
  ] = useStore(
    (state) => [
      state.appConfig,
      state.message.hideChat,
      state.setHideChat,
      state.message.conversationId,
      state.message.recipient,
      state.setConversationId,
      state.setRecipient,
      state.setRecipientId,
      state.setRecipientName,
      state.message.recipientId,
      state.message.recipientName,
    ],
    shallow
  );

  const {
    loadClinicians,
    loaded,
    isLoading,
    resetState: resetLoadCliniciansState,
  } = useLoadClinicians(careSettingId);

  const [postMessageMutation, { data, error, reset: resetPostMessage }] =
    usePostMessageMutation({
      onError: () => {},
    });

  const onSubmit = (input: MessageFormData) => {
    postMessageMutation({
      variables: {
        input: {
          conversationId: conversationId || undefined,
          careSettingId,
          content: input.message,
          recipients: [
            conversationId ? recipient : input.selectedRecipient.value,
          ],
          recipientId: conversationId
            ? recipientId
            : input.selectedRecipient.recipientId,
          recipientName: conversationId
            ? recipientName
            : input.selectedRecipient.recipientName,
          recipientType: 'CLINICIAN',
          shortMessageType: 'BROADCAST_INCLUDED_IN_CONVERSATION',
        },
      },
    });
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    reset: resetForm,
  } = useForm<MessageFormData>({
    mode: 'onChange',
    resolver: yupResolver(
      recipientId
        ? messageValidation(SMS_MIN_CHARS, SMS_MAX_CHARS)
        : clinicianValidation(SMS_MIN_CHARS, SMS_MAX_CHARS)
    ),
    defaultValues: {
      message: defaultMessage,
    },
  });

  const currentValue = watch('message');
  const selectedRecipient = watch('selectedRecipient');

  useEffect(() => {
    // reset the form if the conversationId changes
    resetForm();
    resetPostMessage();
    setValue('message', defaultMessage);
  }, [conversationId]);

  useEffect(() => {
    // reset the message field if message is sent successfully
    // set the conversationId and recipient to configure the component for the next message
    if (data) {
      resetForm();
      setValue('message', defaultMessage);
      setConversationId(data.postMessage.attributes.conversationId);
      setRecipient(data?.postMessage?.attributes?.recipients?.[0] || '');
      setRecipientId(data?.postMessage?.attributes?.recipientId || '');
      setRecipientName(data?.postMessage?.attributes?.recipientName || '');
    }
  }, [data]);

  const asyncSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // close the dropdown when a clinician is selected
    if (selectedRecipient) {
      resetLoadCliniciansState();
    }
  }, [selectedRecipient]);

  useEffect(() => {
    // close the dropdown when clicking outside of it
    const handleClickOutside = (event: MouseEvent) => {
      if (
        asyncSelectRef.current &&
        !asyncSelectRef.current.contains(event.target as Node)
      ) {
        resetLoadCliniciansState();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [asyncSelectRef]);

  const messageFieldErrorText = (e: FieldError) => {
    const isMaxError = e.type === 'max';
    return isMaxError ? characterCount(currentValue, SMS_MAX_CHARS) : e.message;
  };

  return (
    <NewMessageWrapper hideChat={hideChat}>
      <TitleWrapper>
        <TitleIcon onClick={() => setHideChat(true)}>
          <BTHealthIcon icon="UpArrow" size={16} />
        </TitleIcon>
        <SubHeader>
          {conversationId ? recipientName || recipient : 'New message'}
        </SubHeader>
      </TitleWrapper>

      {!conversationId && (
        <DropdownWrapper>
          <Wrapper>
            <div ref={asyncSelectRef}>
              <RHFAsyncDropDown
                fieldName="selectedRecipient"
                control={control}
                isMulti={false}
                loadOptions={loadClinicians}
                placeholder="Enter name"
                menuIsOpen={loaded && !isLoading}
                components={SelectCustomComponents}
              />
            </div>
            {(errors?.selectedRecipient as any)?.message && (
              /*
               * This is a fix for the RHF typescript issue
               * https://github.com/react-hook-form/react-hook-form/issues/987
               */
              <ResponseNotification
                message={(errors?.selectedRecipient as any)?.message}
                notificationType="error"
                alignment="align-left"
              />
            )}
          </Wrapper>
        </DropdownWrapper>
      )}

      <ChatWrapper isExistingChat={conversationId}>
        {conversationId ? (
          <Chat conversationId={conversationId} />
        ) : (
          <MessagePlaceholder />
        )}
      </ChatWrapper>

      <MessageFieldWrapper>
        {error && (
          <ResponseNotification
            message="Message failed to send"
            notificationType="error"
          />
        )}
        {data && (
          <ResponseNotification
            message="Message sent successfully"
            notificationType="success"
          />
        )}
        <TextArea
          id="message"
          helperText={
            characterCount(currentValue, SMS_MAX_CHARS) ||
            `Maximum ${SMS_MAX_CHARS} characters`
          }
          rows={3}
          errorText={
            errors.message?.message && messageFieldErrorText(errors?.message)
          }
          {...register('message')}
        />
        <ButtonWrapper>
          <Button
            id="send-message-button"
            variant="primary"
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
          >
            Send
          </Button>
        </ButtonWrapper>
      </MessageFieldWrapper>
    </NewMessageWrapper>
  );
};
