import type { NavItem } from '@bt-healthcare/ui-toolkit';
import { Exception, Loader, Page } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useStore } from 'store';
import { AppRoutes } from 'routes/Routes';
import { MODULE_SHORT_NAME } from 'App.constants';
import { useAuthUser } from 'auth';
import { getMenuConfig, getNavItems } from 'auth/modules';
import { PageWrapper } from 'styles';

const App = () => {
  const [navItems, setNavItems] = useState<NavItem[]>();
  const { auth, user } = useAuthUser();

  const [menuHeader, setAppConfig] = useStore(
    (state) => [state.menuHeader, state.setAppConfig],
    shallow
  );

  useEffect(() => {
    if (navItems === undefined && user.data) {
      setNavItems(getNavItems(user.data));
      setAppConfig({
        careSettingId:
          user.data.userProfile?.attributes.defaultCareSettingId ?? '',
        organisationName:
          user.data.userProfile?.careSetting?.attributes.name ?? '',
      });
    }
  }, [user]);

  const loading = auth.loading || user.loading;

  if (loading || (!navItems && !user.error)) return <Loader />;
  return (
    <Page
      profile={user.profile}
      showThemeToggle={false}
      menuConfig={getMenuConfig(navItems ?? [], MODULE_SHORT_NAME)}
      menuHeader={menuHeader}
      appTitle="Smart Messaging"
      size="wide"
      onLogout={auth.logout}
    >
      <PageWrapper>{user.error ? <Exception /> : <AppRoutes />}</PageWrapper>
    </Page>
  );
};

export default App;
