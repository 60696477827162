export const SmartMessagingIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="40"
      cy="40"
      r="38.5"
      stroke="url(#paint0_radial_5329_2410)"
      strokeWidth="3"
    />
    <path
      d="M33.7949 54.0339L26.1821 61.998L26.2992 47.1238"
      stroke="url(#paint1_linear_5329_2410)"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3066 57.5504L26.2974 46.7754L64.2442 23L37.3066 57.5504Z"
      stroke="url(#paint2_linear_5329_2410)"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2976 46.6582L15.7568 36.2346L64.2445 23"
      stroke="url(#paint3_linear_5329_2410)"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <radialGradient
        id="paint0_radial_5329_2410"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(78.85 1.95833) rotate(135.295) scale(110.941 101.406)"
      >
        <stop offset="0.19236" stopColor="#5514B4" />
        <stop offset="0.687666" stopColor="#472ECD" />
        <stop offset="1" stopColor="#2962FF" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_5329_2410"
        x1="26.1821"
        y1="62.1578"
        x2="37.0546"
        y2="59.3453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#448AFF" />
        <stop offset="0.539119" stopColor="#472ECD" />
        <stop offset="0.999539" stopColor="#5514B4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5329_2410"
        x1="26.2974"
        y1="57.9216"
        x2="70.4985"
        y2="33.3854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#448AFF" />
        <stop offset="0.539119" stopColor="#472ECD" />
        <stop offset="0.999539" stopColor="#5514B4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5329_2410"
        x1="15.7568"
        y1="46.9124"
        x2="51.3974"
        y2="9.99395"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#448AFF" />
        <stop offset="0.539119" stopColor="#472ECD" />
        <stop offset="0.999539" stopColor="#5514B4" />
      </linearGradient>
    </defs>
  </svg>
);
