import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import { format } from 'date-fns';
import { EMPTY_VALUE } from 'App.constants';
import type { MessageAttributes, SmsStatus } from '../services';

/**
 * Convert a hex colour value to an rbg or rbga value
 *
 * @param hex {string}: '#FF0000'
 * @param alpha {number}: 0.1 - 1
 *
 * input
 *
 * return value 'rgba(255, 0, 0, 0.2)' / 'rgb(255, 0, 0)'
 */
export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

/**
 *
 * @param value
 * @param maxChars
 * input: value: 'sometext', maxChars: 1000
 * output: '992 / 1000'
 */
export const characterCount = (value: string, maxChars: number): string =>
  value ? `${maxChars - value.length} / ${maxChars}` : '';

/**
 * Renders a message timestamp
 *
 * @param dte
 */
export const renderMessageTimeStamp = (dte: string) => {
  const currentMessageDate = format(new Date(), 'dd/MM/yy');
  const sentMessageDate = format(new Date(dte), 'dd/MM/yy');

  if (currentMessageDate === sentMessageDate)
    return format(new Date(dte), 'H:mm');

  return format(new Date(dte), 'dd/MM/yy - H:mm');
};

/**
 * Returns the name, number or EMPTY_VALUE if no name or number
 *
 * @param attributes: MessageAttributes
 */
export const returnMessageNameOrNumber = (attributes: MessageAttributes) => {
  if (attributes?.recipientName) {
    return attributes.recipientName;
  }
  if (attributes?.recipient) {
    return attributes.recipient;
  }
  return EMPTY_VALUE;
};

/**
 * Returns the icon associated to sms type
 *
 * @param attributes: SmsStatus
 */
export const returnMessageIcon = (status: SmsStatus) => {
  if (status === 'FAILED')
    return <BTHealthIcon icon="Warning" size={14} color={colors.grey.grey08} />;

  if (status === 'QUEUED')
    return <BTHealthIcon icon="Time" size={14} color={colors.grey.grey08} />;

  return null;
};
