import 'cross-fetch/polyfill';
import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

import { authLink } from './links/auth';
import { errorLink } from './links/error';
import { httpLink } from './links/http';
import { retryLink } from './links/retry';

export const AuthApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  const { getIdTokenClaims, logout } = useAuth0();
  const client = useRef<ApolloClient<NormalizedCacheObject>>();

  const link = from([
    errorLink(logout, client),
    retryLink,
    authLink(getIdTokenClaims),
    httpLink,
  ]);
  if (!client.current) {
    client.current = new ApolloClient({
      link,
      cache: new InMemoryCache({ addTypename: false }),
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
