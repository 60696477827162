import type { NamedSet } from 'zustand/middleware';

import type { Message } from '../models/Message';
import type { AppState } from './types';

export const messageSlice = (set: NamedSet<AppState>) => ({
  message: {
    hideChat: false,
    conversationId: '',
    recipient: '',
    recipientName: '',
    recipientId: '',
  } as Message,
  setHideChat: (value: boolean) =>
    set(
      (state) => ({ message: { ...state.message, hideChat: value } }),
      false,
      'message/setHideChat'
    ),
  setConversationId: (value: string) =>
    set(
      (state) => ({ message: { ...state.message, conversationId: value } }),
      false,
      'message/setConversationId'
    ),
  setRecipient: (value: string) =>
    set(
      (state) => ({ message: { ...state.message, recipient: value } }),
      false,
      'message/recipient'
    ),
  setRecipientName: (value: string) =>
    set(
      (state) => ({ message: { ...state.message, recipientName: value } }),
      false,
      'message/recipientName'
    ),
  setRecipientId: (value: string) =>
    set(
      (state) => ({ message: { ...state.message, recipientId: value } }),
      false,
      'message/recipientId'
    ),
});
