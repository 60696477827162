import styled from 'styled-components';
import { colors, spacing, device, fontSizes } from '@bt-healthcare/ui-toolkit';
import {
  TOP_NAV_DESKTOP_HEIGHT,
  TOP_NAV_MOBILE_HEIGHT,
  DESKTOP_PADDING,
} from '../../App.constants';

const historyElementsHeight = 135;

export const MessageHistoryWrapper = styled.div`
  min-width: 372px;
  width: 100%;
  background-color: ${colors.base.white};
  box-shadow: 5px 1px 7px -4px rgba(0, 0, 0, 0.2);
  padding: ${spacing.s7} ${spacing.s4} ${spacing.s4} ${spacing.s4};
  z-index: auto;

  height: calc(100vh - ${TOP_NAV_MOBILE_HEIGHT}px);
  overflow: hidden;

  @media (${device.desktop}) {
    height: calc(100vh - ${TOP_NAV_DESKTOP_HEIGHT + DESKTOP_PADDING}px);
    width: 372px;
    z-index: 1;
  }
`;

export const ListTitle = styled.div`
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey10};
  font-weight: 400;
  margin: ${spacing.s6} 0 ${spacing.s2} 0;
`;

export const ScrollArea = styled.div`
  overflow-y: auto;
  height: calc(
    100vh -
      ${TOP_NAV_DESKTOP_HEIGHT + DESKTOP_PADDING + historyElementsHeight}px
  );
`;
