import { HttpLink } from '@apollo/client';

import { getEnvVariable } from 'env.utils';

export const graphqlServerURL = `${getEnvVariable(
  'REACT_APP_SERVICE_GRAPHQL'
)}/graphql`;

export const httpLink = new HttpLink({
  uri: graphqlServerURL,
});
