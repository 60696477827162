import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { Wrapper } from '@bt-healthcare/ui-toolkit';
import { DirectMessages } from './DirectMessages';
import { MessageHistoryItem } from './MessageHistoryItem';
import { MessageHistoryWrapper, ListTitle, ScrollArea } from './styles';
import { useGetCareSettingConversationHistoryQuery } from '../../services';
import { useStore } from '../../store';

export const MessageHistory = () => {
  // store
  const [
    { careSettingId },
    conversationId,
    setConversationId,
    setHideChat,
    setRecipient,
    setRecipientId,
    setRecipientName,
  ] = useStore(
    (state) => [
      state.appConfig,
      state.message.conversationId,
      state.setConversationId,
      state.setHideChat,
      state.setRecipient,
      state.setRecipientId,
      state.setRecipientName,
    ],
    shallow
  );

  const { data, loading, error, startPolling } =
    useGetCareSettingConversationHistoryQuery({
      variables: {
        careSettingId,
        recipientType: 'CLINICIAN',
      },
      // todo: bumping react to 18 breaks the polling within apollo, redo pollInterval when we upgrade
      // pollInterval: 3000,
      // skip: !careSettingId,
    });

  // todo: bumping react to 18 breaks the polling within apollo, the useEffect below is a workaround
  useEffect(() => {
    if (careSettingId) {
      startPolling(3000);
    }
  }, [careSettingId]);

  const handleClickHistoryItem = (
    id: string,
    recipient: string,
    recipientName: string,
    recipientId: string
  ) => {
    setHideChat(false);
    setConversationId(id);
    setRecipient(recipient);
    setRecipientId(recipientId);
    setRecipientName(recipientName);
  };

  return (
    <MessageHistoryWrapper>
      <DirectMessages />
      <ListTitle>History</ListTitle>
      {loading && <Wrapper>...Loading</Wrapper>}
      {error && <Wrapper>Could not load History</Wrapper>}
      <ScrollArea>
        {data?.getCareSettingConversationHistory &&
          data.getCareSettingConversationHistory.map((historyItem) => (
            <MessageHistoryItem
              {...historyItem}
              isActive={
                historyItem.attributes.conversationId === conversationId
              }
              onClick={() =>
                handleClickHistoryItem(
                  historyItem.attributes.conversationId,
                  historyItem.attributes.recipient || '',
                  historyItem.attributes.recipientName || '',
                  historyItem.attributes.recipientId || ''
                )
              }
              key={historyItem.id}
            />
          ))}
      </ScrollArea>
    </MessageHistoryWrapper>
  );
};
