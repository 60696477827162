import styled from 'styled-components';
import { spacing, fontSizes, colors } from '@bt-healthcare/ui-toolkit';
import type { BubbleWrapperProps, StyleInputProps } from './types';

// todo: remove BubbleType and replace once we have an attribute for sms type
export const BubbleWrapper = styled.div<Pick<StyleInputProps, 'smsType'>>`
  display: flex;
  justify-content: ${({ smsType }) =>
    smsType === 'OUTGOING' ? 'flex-end' : 'flex-start'};

  > :first-child {
    margin-right: ${({ smsType }) => (smsType === 'OUTGOING' ? 0 : spacing.s4)};
    display: ${({ smsType }) => (smsType === 'OUTGOING' ? 'none' : 'flex')};
  }

  &:not(:last-child) {
    margin-bottom: ${spacing.s4};
  }
`;

export const SpeechBubble = styled.div<BubbleWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: ${({ color }) => color};
  padding: ${spacing.s4};
  border-radius: 8px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing.s2};

  > :first-child {
    margin-right: ${spacing.s2};
  }
`;

export const Time = styled.div`
  font-size: ${fontSizes.xs};
  color: ${colors.grey.grey08};
`;

export const MessageWrapper = styled.div`
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey10};
  word-break: break-all;
`;

export const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing.s2};
  font-size: ${fontSizes.xs};
  color: ${colors.rag.light.red};
  margin-bottom: ${spacing.s4};
`;
