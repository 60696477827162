/* eslint-disable no-console */
import Config from './config.json';

type EnvKeys = typeof process.env;
export type EnvVars = keyof Pick<
  EnvKeys,
  | 'REACT_APP_ENV'
  | 'REACT_APP_AUTH_CLIENT_ID'
  | 'REACT_APP_AUTH_AUTHORITY'
  | 'REACT_APP_SERVICE_GRAPHQL'
>;

export const getEnvVariable = (variable: EnvVars) =>
  Config[variable] && !Config[variable].includes('$REACT_APP')
    ? Config[variable]
    : process.env[variable]!;
