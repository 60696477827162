import { SmartMessagingIcon } from './assets/SmartMessagingIcon';

export const SMS_MIN_CHARS = 1;
export const SMS_MAX_CHARS = 160;
export const TEMPLATE_TITLE_MAX_CHARS = 50;
export const REQUIRED_FIELD_TEXT = 'This is a required field';
export const MODULE_SHORT_NAME = 'smartmessaging';

export const TOP_NAV_MOBILE_HEIGHT = 64;
export const TOP_NAV_DESKTOP_HEIGHT = 120;
export const DESKTOP_PADDING = 48;

export const EMPTY_VALUE = '--';

export const homeOptions = [
  {
    link: '#/lists',
    svgComponent: <SmartMessagingIcon />,
    title: 'Lists',
    id: 'lists',
    includeIconBackground: false,
  },
  {
    link: '#/templates',
    svgComponent: <SmartMessagingIcon />,
    title: 'Templates',
    id: 'templates',
    includeIconBackground: false,
  },
  {
    link: '#/message-patient',
    svgComponent: <SmartMessagingIcon />,
    title: 'Message Patient',
    id: 'message-patient',
    includeIconBackground: false,
  },
];
