import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { clearStore } from 'store';
import { useGetUserProfileQuery } from 'services';
import { getUserProfile, hasAuthorisation, moduleLogout } from './modules';

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const client = useApolloClient();
  const { data, error, loading } = useGetUserProfileQuery({
    variables: user?.sub ? { safId: user.sub } : undefined,
    skip: !isAuthenticated || !user,
    onCompleted: () => {
      clearStore();
    },
  });

  const handleLogout = () => {
    moduleLogout(logout, client);
  };

  return {
    user: {
      data,
      loading,
      error,
      profile: getUserProfile(user, data),
    },
    auth: {
      logout: handleLogout,
      loading: isLoading,
      userProfile: user,
      isAuthenticated,
      isAuthorised: hasAuthorisation(data),
    },
  };
};
