import { Routes, Route } from 'react-router-dom';
import { PageNotFound } from '@bt-healthcare/ui-toolkit';

import { Home } from 'pages/Home';
import { ProtectedRoute } from './ProtectedRoute';

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<ProtectedRoute />}>
      <Route path="/" element={<Home />} />
    </Route>
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
