import { colors, fontSizes, spacing, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import {
  TOP_NAV_DESKTOP_HEIGHT,
  TOP_NAV_MOBILE_HEIGHT,
  DESKTOP_PADDING,
} from '../../App.constants';
import type { NewMessageStyles } from './types';

// todo: revisit variables to use globally, i.e. nav height etc. to consistently change size;
const TITLE_HEIGHT = 80;
const RECIPIENT_SELECTOR_HEIGHT = 100;
const MESSAGE_FIELD_HEIGHT = 214;
const CONTAINER_PADDING = 24;

const FULL_MOBILE_HEIGHT =
  TOP_NAV_MOBILE_HEIGHT +
  TITLE_HEIGHT +
  RECIPIENT_SELECTOR_HEIGHT +
  MESSAGE_FIELD_HEIGHT +
  CONTAINER_PADDING;
const FULL_DESKTOP_HEIGHT =
  TOP_NAV_DESKTOP_HEIGHT +
  TITLE_HEIGHT +
  RECIPIENT_SELECTOR_HEIGHT +
  MESSAGE_FIELD_HEIGHT +
  CONTAINER_PADDING;

export const NewMessageWrapper = styled.div<Pick<NewMessageStyles, 'hideChat'>>`
  width: 100%;
  height: calc(100vh - ${TOP_NAV_MOBILE_HEIGHT}px);
  border-radius: 0 8px 8px 0;
  background-color: ${colors.base.white};
  align-items: stretch;

  display: ${({ hideChat }) => (hideChat ? 'none' : 'block')};
  position: absolute;
  margin-top: ${TOP_NAV_MOBILE_HEIGHT}px;
  top: 0;
  left: 0;

  @media (${device.desktop}) {
    height: calc(100vh - ${TOP_NAV_DESKTOP_HEIGHT + DESKTOP_PADDING}px);
    display: block;
    flex-direction: column;
    position: relative;
    margin-top: 0;
    top: auto;
    left: auto;
  }
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChatWrapper = styled.div<Pick<NewMessageStyles, 'isExistingChat'>>`
  height: ${({ isExistingChat }) =>
    `calc(100vh - ${
      FULL_MOBILE_HEIGHT - (isExistingChat ? RECIPIENT_SELECTOR_HEIGHT : 0)
    }px)`};
  overflow-y: auto;

  @media (${device.desktop}) {
    height: ${({ isExistingChat }) =>
      `calc(100vh - ${
        FULL_DESKTOP_HEIGHT +
        DESKTOP_PADDING -
        (isExistingChat ? RECIPIENT_SELECTOR_HEIGHT : 0)
      }px)`};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey.grey05};
  padding: 0 ${spacing.s6};
  height: ${TITLE_HEIGHT}px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 ${spacing.s5};
  border-bottom: 1px solid ${colors.grey.grey05};
  height: ${RECIPIENT_SELECTOR_HEIGHT}px;

  > :first-child {
    width: 100%;
  }
`;

export const MessageFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 ${spacing.s6};
  min-height: ${MESSAGE_FIELD_HEIGHT}px;
`;

export const MobileLabel = styled.div`
  margin-top: ${spacing.s4};
  font-size: ${fontSizes.base};
  color: ${colors.grey.grey10};
`;

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  width: 130px;
`;

export const TitleIcon = styled.h4`
  display: block;
  transform: rotate(-90deg);
  margin-right: ${spacing.s5};
  cursor: pointer;

  @media (${device.desktop}) {
    display: none;
  }
`;
