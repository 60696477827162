import { Col, Grid } from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';

import { NewMessage } from 'components/NewMessage';
import { MessageHistory } from 'components/MessageHistory';
import { useStore } from 'store';
import { MessageWrapper } from './styles';

export const Home = () => {
  const [setMenuHeader] = useStore((state) => [state.setMenuHeader], shallow);
  useEffect(() => {
    setMenuHeader('Smart Messaging');
  }, []);

  return (
    <Grid>
      <Col colStart={{ xs: 1, xl: 3 }} xs={12} xl={8}>
        <MessageWrapper>
          <MessageHistory />
          <NewMessage />
        </MessageWrapper>
      </Col>
    </Grid>
  );
};
