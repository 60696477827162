import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { AsyncDropDown } from '@bt-healthcare/ui-toolkit';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { RHFAsyncDropDownProps } from './types';

/** React hook form wrapper for ButtonDropDown component  */
export const RHFAsyncDropDown = <T extends FieldValues>({
  fieldName,
  components,
  control,
  defaultValue,
  loadOptions,
  disabled = false,
  isMulti = false,
  menuIsOpen = false,
  placeholder,
}: RHFAsyncDropDownProps<T>) => (
  <Controller
    name={fieldName}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, onBlur, name, value } }) => (
      <AsyncDropDown
        id={name}
        placeholder={placeholder}
        value={value as TypedOptionType<string>}
        loadOptions={loadOptions}
        name={name}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
        isMulti={isMulti}
        menuIsOpen={menuIsOpen}
        components={components}
      />
    )}
  />
);
