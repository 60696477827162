import styled from 'styled-components';
import { colors, fontSizes } from '@bt-healthcare/ui-toolkit';

export const DirectMessagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const IconWrapper = styled.h4`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${colors.primaryIndigo.indigo08};
  font-size: ${fontSizes.h4};
  cursor: pointer;
`;
