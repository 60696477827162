import type { MessageHistoryItemProps } from './types';
import { MessageIcon } from '../../../assets/message-icon';
import {
  renderMessageTimeStamp,
  returnMessageIcon,
  returnMessageNameOrNumber,
} from '../../../utils';
import {
  MessageHistoryItemWrapper,
  ContentWrapper,
  TopLine,
  PhoneNumber,
  Message,
  TimeStamp,
} from './styles';
import { EMPTY_VALUE } from '../../../App.constants';

export const MessageHistoryItem = ({
  attributes,
  isActive,
  onClick,
}: MessageHistoryItemProps) => (
  <MessageHistoryItemWrapper isActive={isActive} onClick={onClick}>
    <MessageIcon />
    <ContentWrapper>
      <TopLine>
        <PhoneNumber>
          {returnMessageIcon(attributes.smsStatus)}
          {returnMessageNameOrNumber(attributes)}
        </PhoneNumber>
        <TimeStamp>
          {attributes.audit.created
            ? renderMessageTimeStamp(attributes.audit.created)
            : EMPTY_VALUE}
        </TimeStamp>
      </TopLine>
      <Message>{attributes.content}</Message>
    </ContentWrapper>
  </MessageHistoryItemWrapper>
);
