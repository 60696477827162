import { NewMessageSVG } from './assets/NewMessageSVG';
import { MessagePlaceholderWrapper, Content, Title, Text } from './styles';

export const MessagePlaceholder = () => (
  <MessagePlaceholderWrapper>
    <Content>
      <NewMessageSVG />
      <Title>You’re starting a new message</Title>
      <Text>Search for a contact and type your message below</Text>
    </Content>
  </MessagePlaceholderWrapper>
);
