import type { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { getEnvVariable } from 'env.utils';

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => (
  <Auth0Provider
    domain={getEnvVariable('REACT_APP_AUTH_AUTHORITY')}
    clientId={getEnvVariable('REACT_APP_AUTH_CLIENT_ID')}
    redirectUri={window.location.origin}
  >
    {children}
  </Auth0Provider>
);
