import { useState } from 'react';
import debounce from 'lodash.debounce';
import { useCareSettingCliniciansLazyQuery } from '../../../services';

export const useLoadClinicians = (careSettingId: string) => {
  const [getClinicianData] = useCareSettingCliniciansLazyQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const loadAsyncClinicians = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if (inputValue.length < 2) callback([]);

    setIsLoading(true);
    const result = await getClinicianData({
      variables: {
        careSettingId,
        name: inputValue,
      },
    });

    if (result.data) {
      setIsLoading(false);
      setLoaded(true);
      const returnValue = result.data.careSettingClinicians?.map(
        (clinician) => ({
          value: clinician.attributes.res_mobileNumber,
          label: clinician.attributes.displayName,
          recipientId: clinician.id,
          recipientName: clinician.attributes.displayName,
        })
      );
      callback(returnValue);
    } else {
      setIsLoading(false);
      setLoaded(false);
      callback([]);
    }
  };
  const debounceLoadAsyncClinicians = debounce(loadAsyncClinicians, 500);

  return {
    loadClinicians: debounceLoadAsyncClinicians,
    isLoading,
    loaded,
    resetState: () => {
      setLoaded(false);
      setIsLoading(false);
    },
  };
};
