import { getModulePath } from '@bt-healthcare/ui-toolkit';
import { useAuthUser } from 'auth';
import { Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  const { auth } = useAuthUser();
  if (!auth.isAuthorised.module || !auth.isAuthorised.app) {
    const rootPath = `${getModulePath('aim', window.location.hostname)}`;
    const redirectPath = !auth.isAuthorised.app
      ? rootPath
      : `${rootPath}/#/unauthorised`;
    window.location.replace(redirectPath);
    return null;
  }
  return <Outlet />;
};
