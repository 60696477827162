import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { LogoutOptions, User } from '@auth0/auth0-react';
import type { NavItem, MenuConfig, Profile } from '@bt-healthcare/ui-toolkit';
import { MODULE_SHORT_NAME } from 'App.constants';

import type { GetUserProfileQuery } from 'services';
import { clearStore } from 'store';

export const getMenuConfig = (navItems: NavItem[], module: string) =>
  ({
    navItems,
    hostname: window.location.hostname,
    module,
  } as MenuConfig);

export const getNavItems = ({ userProfile }: GetUserProfileQuery) =>
  userProfile?.userModuleAvailability?.map((item) => {
    const { attributes } = item!;
    const isActive = attributes.availabilityStatus === 'ACCESSIBLE';
    return {
      module: attributes.moduleShortName,
      label: attributes.moduleName,
      isActive,
    } as NavItem;
  });

export const hasAuthorisation = (data?: GetUserProfileQuery) => {
  if (data?.userProfile?.attributes.userState === 'ENABLED') {
    if (data?.userProfile?.userModuleAvailability == null)
      return { module: false, app: true };
    const moduleAuthorisation =
      data.userProfile.userModuleAvailability.find(
        (item) =>
          item?.attributes?.moduleShortName === MODULE_SHORT_NAME &&
          item.attributes.availabilityStatus === 'ACCESSIBLE'
      ) !== undefined;

    return { module: moduleAuthorisation, app: true };
  }
  return { module: false, app: false };
};

export const moduleLogout = (
  logout: (options?: LogoutOptions) => void,
  apolloClient?: ApolloClient<NormalizedCacheObject | {}>
) => {
  clearStore();
  apolloClient?.clearStore();
  logout({ returnTo: window.location.origin });
};

export const getUserProfile = (
  user?: User,
  data?: GetUserProfileQuery
): Profile => ({
  mail: user?.email || '',
  organisation: data?.userProfile?.careSetting?.attributes.name || 'BT Health',
  organisationShortName:
    data?.userProfile?.careSetting?.attributes.shortName || 'BH',
  displayName: user?.name || user?.email || '',
});
